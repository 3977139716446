import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay, Zoom, Navigation, Pagination } from 'swiper';

// Install Swiper modules
SwiperCore.use([Autoplay, Zoom, Navigation, Pagination]);

// Resolve Tailwind configuration for colors
const fullConfig = resolveConfig(tailwindConfig);
const { theme: { colors: { basic: { white: basic_white } } } } = fullConfig;

const Carousel = ({ className, isShowPagination, sliderList, autoplayDelay, breakpoints, spaceBetween, loop, centeredSlides, isAutoPlay, isSingleSlide, navigationElement, paddingBottom, horizontalSpacing, paddingTop, slidesPerView, freeMode, slidesPerGroup, speed }) => {
    
    // Memoize the styles for better performance
    const swiperStyles = useMemo(() => ({
        width: '100%',
        '--swiper-navigation-color': basic_white,
        '--swiper-pagination-color': basic_white,
        paddingBottom,
        paddingLeft: horizontalSpacing,
        paddingRight: horizontalSpacing,
        paddingTop
    }), [paddingBottom, horizontalSpacing, paddingTop, basic_white]);

    // Handle zoom state change to manage autoplay
    const handleZoomStateChange = useCallback((state) => {
        if (state.zoom.currentScale === 1) {
            state.autoplay.stop();
        } else {
            state.autoplay.start();
        }
    }, []);

    return (
        <Swiper
            onZoomChange={handleZoomStateChange}
            className={`${className} carousel--default ${isSingleSlide ? '' : ''}`}
            style={swiperStyles}
            {...(isAutoPlay && { autoplay: { delay: autoplayDelay, disableOnInteraction: false } })}
            zoom={false}
            navigation={{ nextEl: `.${navigationElement}Arrow--next`, prevEl: `.${navigationElement}Arrow--prev`, disabledClass: 'swiperArrow--disabled' }}
            {...(isShowPagination && { pagination: { clickable: true } })}
            loop={loop && sliderList.length !== 1}
            spaceBetween={spaceBetween}
            breakpoints={breakpoints}
            centeredSlides={centeredSlides}
            slidesPerView={slidesPerView}
            freeMode={freeMode}
            slidesPerGroup={slidesPerGroup}
            speed={speed}
        >
            {sliderList?.map((item, index) => (
                <SwiperSlide key={index}>
                    {item}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

Carousel.defaultProps = {
    className: '',
    isShowPagination: false,
    autoplayDelay: 4000,
    loop: false,
    centeredSlides: false,
    spaceBetween: 0,
    isAutoPlay: false,
    isSingleSlide: false,
    horizontalSpacing: null,
    paddingBottom: 0,
    paddingTop: 0,
    slidesPerView: 1,
    freeMode: false,
    slidesPerGroup: 1,
    speed: 300
};

Carousel.propTypes = {
    className: PropTypes.string,
    isShowPagination: PropTypes.bool,
    sliderList: PropTypes.array.isRequired,
    autoplayDelay: PropTypes.number,
    breakpoints: PropTypes.object,
    spaceBetween: PropTypes.number,
    loop: PropTypes.bool,
    centeredSlides: PropTypes.bool,
    isAutoPlay: PropTypes.bool,
    isSingleSlide: PropTypes.bool,
    navigationElement: PropTypes.string.isRequired,
    paddingBottom: PropTypes.number,
    horizontalSpacing: PropTypes.number,
    paddingTop: PropTypes.number,
    slidesPerView: PropTypes.number,
    freeMode: PropTypes.bool,
    slidesPerGroup: PropTypes.number,
    speed: PropTypes.number
};

export default Carousel;
