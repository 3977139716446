import React from 'react'
import Text from 'atoms/Text'
import LinkAnchor from 'atoms/LinkAnchor'

const HighlightLogin = ({ className = '', url}) => {
    return (
        <Text type='subtitle' className={`${className} bg-other-yellow-400 px-5 py-2 text-gray-900`}>To get list of doctors in your area    
        <LinkAnchor className='inline text-primary1-500 underline text-sm font-normal font-open pl-2' href={url}>Login Here</LinkAnchor>
        </Text>
    )
}

export default HighlightLogin