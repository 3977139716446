const locationFormatter = ({ address, locality, city, state, country, pincode }) => {
    let str = '';
    if (address && address !== null) {
        str = `${str}${address}, `;
    }
    if (locality && locality !== null) {
        str = `${str}${locality}, `;
    }
    if (city && city !== null) {
        str = `${str}${city}, `;
    }
    if (state && state !== null) {
        str = `${str}${state}, `;
    }
    if (country && country !== null) {
        str = `${str}${country}, `;
    }
    if (pincode && pincode !== null) {
        str = `${str}${pincode}, `;
    }
    return str;
};


export default locationFormatter