import { getAuth } from "services/identity.service";

export const getBannerUrl = ({ linkingType, bannerLinkingId, pageUrl, bannerType }) => {
    const auth = getAuth()

    console.log('linkingType :>> ', linkingType, pageUrl);
    switch (linkingType) {
        case 1:
            return `/medicines/allMedicines?id=${bannerLinkingId}&type=${bannerType}`;

        case 2:
            return !auth ? '/sign-up-practitioner' : `/quizzes/${Buffer.from(bannerLinkingId.toString()).toString("base64")}`

        case 3:
            // moengage.trackEvent(WebinarEntity.webinar_click_banner_link.event_name, {})
            return !auth ? '/sign-up-practitioner' : `/webinar/${Buffer.from(bannerLinkingId.toString()).toString("base64")}`;

        default:
            return pageUrl;
    }
}