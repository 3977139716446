import React from 'react'
import Heading from 'atoms/Heading'
import List from 'atoms/List'
import Card from 'atoms/Card'
import IconWithLabel from 'molecules/IconWithLabel'
import Text from 'atoms/Text'
import LinkAnchor from 'atoms/LinkAnchor'
import Skeleton from 'react-loading-skeleton'

const ColorBgListing = ({ className = '', title, subTitle, list, location, phoneNumber, address = false, shareUrl, isLoading }) => {
    return (
        <Card className={`${className} rounded-lg shadow-none card--regular p-4 pb-1`}>
            {isLoading
                ? <div className='flex flex-col gap-1'>
                    <Heading type='h3' className='font-Open-Sans mb-2 text-sm font-semibold text-gray-600'>{title}</Heading>
                    <Skeleton containerClassName='w-full' height={16} className='rounded-2xl' style={{ borderRadius: 50 }} />
                    <Skeleton containerClassName='w-full' height={16} className='rounded-2xl' style={{ borderRadius: 50 }} />
                    <Skeleton containerClassName='w-full' height={16} className='rounded-2xl' style={{ borderRadius: 50 }} />
                </div>
                : <>
                    <Heading type='h3' className='font-Open-Sans mb-2 text-sm font-semibold text-gray-600'>{title}</Heading>
                    {subTitle && <Text type='subtitle' className='text-gray-600 font-Open-Sans -mt-1 mb-3 text-14-16'>{subTitle}</Text>}
                    {list && <List classList='mb-1.5 last-of-type:mb-0' className='list-disc ml-0 text-xs text-gray-900 grid grid-cols-2 md:grid-cols-1 gap-x-3 gap-y-0' list={list} />}
                    {address ?
                        <>
                            <IconWithLabel
                                iconContainerClass='flex'
                                containerClass='gap-2 w-full items-start flex'
                                iconClass="w-6 fill-none stroke-gray-900 mt-0.5"
                                iconType='IconLocationRound'
                                label={location}
                                labelClass="flex-wrap text-sm font-normal font-open  text-gray-900"
                            />
                            {shareUrl && <LinkAnchor className='inline-block text-primary1-500 underline text-sm font-normal font-open pl-8 line-clamp-1 mt-3' href={shareUrl}>{shareUrl}</LinkAnchor>}
                            <IconWithLabel
                                containerClass='gap-2 w-full items-start mt-3.5'
                                iconClass="w-5 fill-gray-900 stroke-gray-900 hover:fill-primary1-500"
                                iconType="IconPhone"
                                label={phoneNumber}
                                labelClass="flex-wrap text-sm font-normal font-open text-gray-900 line-clamp-1"
                            />
                        </> : null}
                </>}
        </Card>
    )
}

export default ColorBgListing