import React, { useEffect, useState } from 'react'

import SkeletonLoader from 'atoms/skeleton/SkeletonLoader'
import DoctorAppointmentCard from 'molecules/v1/DoctorAppointmentCard'
import { getAllDoctors } from 'services/ayurvedic-doctor.service'
import { useRouter } from 'next/router'
import Text from 'atoms/Text'
import ImageNext from 'atoms/ImageNext'
import { getClinicDoctors } from 'services/clinic.service'
import { usernameSalutation } from 'utils'
import CarouselAutoWidth from 'molecules/CarouselAutoWidth'
import { getAuth } from 'services/identity.service'

const ConsultDoctorList = ({ page = 1, isSortBySearch = false, limit = 10, onRequestAppointment, classGridContainer, isGridView, consultDoctor, doctorTag = '', skeletonLength = 2, requestAppointmentText, clinicId, isShowSlider=false }) => {
    const router = useRouter();
    const [count, setCount] = useState(0);
    const [doctorList, setDoctorList] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        handleResize(); 
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const loadDoctorList = async ({ search, city, pincode }) => {
        setLoading(true);
        if (clinicId) {
            const responseSmartCenterRes = await getClinicDoctors(clinicId, 0, 3);

            if (responseSmartCenterRes.status) {
                const rows = responseSmartCenterRes?.entity?.rows.map((item) => ({
                    fullNameSalutation: usernameSalutation({ fullName: item?.User?.fullName, userType: item?.User?.userType, title: item?.User?.tbl_doctor_profile?.title }),
                    profilePic: item?.User?.tbl_doctor_profile?.profile_pic,
                    experience: item?.experienceText,
                    qualifications: item?.qualificationsText,
                    slug: item?.User?.slug,
                    city: item?.User?.tbl_doctor_profile?.city,
                    specializations: item?.servicesAndSepcilizationsText,
                    consultationFee: item?.User?.tbl_doctor_profile?.consultationFee,
                    ...item,
                    id: item?.doctor_id,
                }))
                const { count } = responseSmartCenterRes.entity;
                setCount(count);
                setDoctorList(rows);
            }
            setLoading(false);
        } else {
            const response = await getAllDoctors({ page, limit, city, search, type: doctorTag, pincode, isCurated: 1 });
            if (response.status) {
                const { count, rows } = response.entity;
                setCount(count);
                setDoctorList(rows);
            }
            setLoading(false);
        }
    }

    useEffect(async () => {
        loadDoctorList({ search: consultDoctor?.search || '', city: consultDoctor?.location || '', pincode: consultDoctor?.pincode || '' });
    }, [page]);

    const itemNotFound = !loading && doctorList?.length === 0;

    return (
        <div className={`${itemNotFound ? '' : classGridContainer} grid grid-cols-1 gap-3 justify-between`}>
            {
                loading
                    ? <SkeletonLoader length={skeletonLength} type="clinicDoctorSummery" />
                    : isShowSlider ?
                        <CarouselAutoWidth
                            title={auth ? 'Top Doctors' : 'Consult a Doctor'}
                            isShowArrow={isDesktop}
                            className='relative z-9'
                            headingRowClasses='px-4'
                            autoplayDelay={6000}
                            isAutoPlay={true}
                            breakpoints={{
                                '0': {
                                    slidesPerView: 1.1
                                },
                                '620': {
                                    slidesPerView: 2.1
                                },
                                '1024': {
                                    slidesPerView: 3
                                }
                            }}
                            list={
                                doctorList.map((doctor, index) => 
                                    <DoctorAppointmentCard
                                        className={`pt-3 px-3 pb-2 md:py-3.5 md:px-4`}
                                        isGridView={isGridView}
                                        key={index}
                                        city={doctor.city}
                                        degree={doctor.qualifications}
                                        experience={doctor.experience}
                                        fullName={doctor.fullNameSalutation}
                                        image={`${process.env.NEXT_PUBLIC_PHP_BUCKET_URL}${doctor.profilePic}`}
                                        specializations={doctor.specializations}
                                        onRequestAppointment={() => onRequestAppointment(doctor)}
                                        requestAppointmentText={requestAppointmentText}
                                        doctorsWebPageUrl={doctor?.href}
                                        consultationFee={doctor?.consultationFee}
                                    />
                                )
                            }
                        /> :
                        doctorList.map((doctor, index) => (
                            <DoctorAppointmentCard
                                className={`pt-3 px-3 pb-2 md:py-3.5 md:px-4`}
                                isGridView={isGridView}
                                key={index}
                                city={doctor.city}
                                degree={doctor.qualifications}
                                experience={doctor.experience}
                                fullName={doctor.fullNameSalutation}
                                image={`${process.env.NEXT_PUBLIC_PHP_BUCKET_URL}${doctor.profilePic}`}
                                specializations={doctor.specializations}
                                onRequestAppointment={() => onRequestAppointment(doctor)}
                                requestAppointmentText={requestAppointmentText}
                                doctorsWebPageUrl={doctor?.href}
                                consultationFee={doctor?.consultationFee}
                            />
                        ))
            }

            {itemNotFound && <div className='my-9'>
                <ImageNext src='/images/noResult.svg' width={658} height={200} alt='image not found' />
                <Text className='mt-5 text-gray-500 px-10 text-center'>
                    We couldn't find any doctors for you. Your search didn't match anything. Please try again.
                </Text>
            </div>
            }
        </div>
    )
}

export default ConsultDoctorList