import React from 'react'
import Heading from "./../atoms/Heading";
import Carousel from 'atoms/Carousel';
import IconAngleLeft from 'atoms/SvgIcons/IconAngleLeft.svg';
import IconAngleRight from 'atoms/SvgIcons/IconAngleRight.svg';
import { moEngageFeedFeaturedCarouselClick } from '@/components/feeds/FeedMoEngage';

const CarouselAutoWidth = ({ className = "", headingRowClasses = '', title, list, breakpoints, spaceBetween = 12, autoplayDelay = 6000, isShowArrow = false, navigationElement, isAutoPlay, practitionerHomepage = false}) => {
    return (
        list?.length ? <div className={`${className} relative overflow-x-hidden w-full py-0'}`}>
            <Heading type="h3" textColor="text-gray-600" className={`${headingRowClasses} font-Open-Sans text-base lg:text-xl font-semibold lg:font-bold text-gray-600 mb-3.5`}>
                {title}
            </Heading>
            {isShowArrow && <>
                <button onClick={moEngageFeedFeaturedCarouselClick({POSITION:'Left'})} className='swiperArrow swiperArrow--prev customArrow customArrow--prev swiperArrow--blue group' data-automation='custom-left-arrow'>
                    <IconAngleLeft className='w-12 text-basic-white group-hover:text-primary1-500' />
                </button>
                <button onClick={moEngageFeedFeaturedCarouselClick({POSITION:'Right'})} className='swiperArrow swiperArrow--next customArrow customArrow--next swiperArrow--blue group mr-1 md:mr-0' data-automation='custom-right-arrow'>
                    <IconAngleRight className='w-12 fill-basic-white group-hover:fill-primary1-500' />
                </button>
            </>}
            <Carousel
                breakpoints={breakpoints}
                spaceBetween={spaceBetween}
                autoplayDelay={autoplayDelay}
                navigationElement={navigationElement || 'custom' }
                sliderList={list}
                isAutoPlay={isAutoPlay}
                isSingleSlide={'h-full'}
            />
        </div> : null
    )
}
export default CarouselAutoWidth
