import React from 'react'

import ImageNext from 'atoms/ImageNext'
import Text from 'atoms/Text'
import { CLR } from 'styles/theme'
import useWindowSize from 'customHook/useWindowSize'

const NgSteps = ({ className, src, description, isEven, isLastEl, stepNo, isHowItWork = false, }) => {
    const [width] = useWindowSize();
    return (
        <div className={`${className} lg:flex-col basis-full md:basis-[49%] lg:basis-auto w-full flex items-center gap-2 xxs:gap-7.5 md:gap-y-2`}>

            {
                isHowItWork == false && width < 768 &&
                <div className={`absolute w-[2px] border-l-2 border-dashed border-gray-400 top-2 bottom-2 left-10 transform -translate-x-1/2}`}>
                </div>
            }
            <div className='relative'>
                <div className={`${isHowItWork ? 'w-[60px] sm:w-[60px]' : 'w-[80px] xxs:w-[90px] p-0.5'} rounded-full aspect-square relative z-2 shrink-0'`}>
                    <div className={`${isHowItWork ? 'p-4' : ' border-4'} bg-primary1-900 border-basic-whitePure w-full h-full rounded-full overflow-hidden text-center flex justify-center items-center relative z-2`}>
                        {isHowItWork ?
                            <ImageNext src={src} width={40} height={40} alt={description} />
                            :
                            <ImageNext src={src} width={40} height={40} alt={description} />
                        }
                    </div>
                </div>
            </div>

            <div>
                {stepNo && <Text type='bodySmall' className='relative z-1 lg:text-center text-[10px]' color={CLR.gray600.text} >
                    Step {stepNo}
                </Text>}
                <Text className={`${isHowItWork ? 'text-14-18 lg:max-w-[180px] !font-normal' : 'leading-7 lg:max-w-[148px]'} lg:text-center`} fontWeight={600} color={CLR.gray900.text} >
                    {description}
                </Text>
            </div>
        </div>
    )
}

export default NgSteps