import React from 'react'

const LoadingStatic = ({ className }) => {
    return (
        <div className={`${className} relative w-full`}>
            <div className={`loader loader__absolute`}>
                <div className='loader__figure relative'></div>
            </div>
        </div>
    )
}

export default LoadingStatic