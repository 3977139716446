import React from 'react'
import Card from "atoms/Card";
import ImageNext from "atoms/ImageNext";
import Text from "atoms/Text";
import IconWithLabel from "molecules/IconWithLabel";
import Button from 'atoms/Button';
import LinkAnchor from 'atoms/LinkAnchor';

const DoctorAppointmentCard = ({ className, image, fullName, degree, city, experience, specializations, onRequestAppointment, isGridView = false, requestAppointmentText = 'Request Appointment', doctorsWebPageUrl, consultationFee }) => {
    
    return (
        <Card className={`${className} ${isGridView ? specializations ? 'min-h-[200px] lg:min-h-[310px] md:min-h-[310px] md:grid-cols-1 !flex flex-col w-full max-w-full md:max-w-[200px] item-center' : 'md:grid-cols-1 !flex flex-col w-full max-w-full md:max-w-[200px] item-center' : 'md:grid-cols-[auto_170px] md:items-center border border-primary1-300'} md:grid md:gap-4 rounded-lg cursor-pointer hover:shadow-extraLight transition-all duration-300 card--regular relative overflow-hidden`}>
            <div className={`${isGridView ? 'flex-grow' : ''}`}>
                <div className={`${isGridView ? 'flex-row md:flex-col gap-3.5' : '' } flex gap-3.5`}>
                    <div className={`${isGridView ? 'mx-auto' : ''} relative w-full max-w-[100px] xxs:max-w-[120px] h-full`}>
                        <div className="aspect-[160/120] bg-gray-100 rounded-lg overflow-hidden relative">
                            <ImageNext className="w-full h-full" src={image} alt={fullName} layout="fill" objectFit="contain" objectPosition="center" defaultImageFor="avatar" />
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex items-center gap-5">
                            <LinkAnchor href={doctorsWebPageUrl}>
                                <Text className={"text-14-16 line-clamp-1 hover:text-primary1-500"} fontWeight={600}>
                                    {fullName}
                                </Text>
                            </LinkAnchor>
                        </div>
                        <IconWithLabel
                            iconContainerClass={'flex'}
                            containerClass="gap-2 w-full items-start flex items-center"
                            iconClass={"w-4 fill-none stroke-gray-900 mt-0.5"}
                            iconType={'IconLocationRound'}
                            label={city}
                            labelClass={"flex-wrap text-12-16 font-normal font-open  text-gray-900 line-clamp-1"}
                        />
                        <IconWithLabel
                            iconContainerClass={'flex'}
                            containerClass="gap-2 w-full items-start flex items-center"
                            iconClass={"w-4 fill-none stroke-gray-900 mt-0.5"}
                            iconType={'IconDegree'}
                            label={degree}
                            labelClass={"flex-wrap text-12-16 font-normal font-open  text-gray-900 line-clamp-1"}
                        />
                        <IconWithLabel
                            containerClass='gap-2 w-full items-start'
                            iconClass="w-4 fill-gray-900 stroke-gray-900 hover:fill-primary1-500"
                            iconType={'IconExperience'}
                            label={experience}
                            labelClass="flex-wrap text-12-16 font-normal font-open text-gray-600 line-clamp-1"
                        />
                        {
                            (specializations && specializations !== null) && <div className={`flex-col text-semantic-success_base !leading-3`}>
                                <Text className={"text-semantic-success_base inline text-12-16 leading-3"} fontWeight={600}>
                                    <p className="inline">
                                        {
                                            JSON.stringify(specializations)
                                                .split(', ')
                                                .filter((item) => item !== null)
                                                .map((item, index) => index < 3 && item)
                                                .filter(item => item !== false)
                                                .join(', ')
                                        }
                                    </p>
                                </Text>
                                <Text className={"text-semantic-success_base inline text-12-16 -ml-3 bg-basic-white"} fontWeight={600}>
                                    {
                                        (specializations && JSON.stringify(specializations).split(', ').length > 3) && `...+${specializations.split(', ').length - 3} more`
                                    }
                                </Text>
                            </div>
                        }
                        <Text className={"text-gray-900 inline text-12-16 bg-basic-white"} fontWeight={600}> {consultationFee ? `₹${consultationFee} Consultation Fee` : 'FREE Consultation'} 
                        </Text>
                    </div>
                </div>
            </div>
            {onRequestAppointment && <LinkAnchor href={doctorsWebPageUrl}> <Button
                className='mt-2 lg:mt-0 md:mt-0 p-1 min-h-8 mx-auto rounded-lg text-12-16 md:text-14-20 font-semibold w-full max-w-[300px]'
            >{requestAppointmentText}</Button> </LinkAnchor>}
        </Card>
    );
};

export default DoctorAppointmentCard;