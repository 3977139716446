import React from 'react'

import Text from 'atoms/Text'
import { CLR } from 'styles/theme'
import Container from 'atoms/Container'
import NgSteps from 'molecules/NgSteps'
import IconAngleRight from 'atoms/SvgIcons/IconAngleRight.svg'

const HowItWorkSteps = ({ className = '', heading, list, stepNo }) => {
    return (
        <div className={`${className}`}>
            <Container type='static' className='px-4 relative w-full max-w-[1200px] mx-auto'>
                <Text  className='text-18-20 text-center mb-2 relative z-1' fontWeight={600} color={CLR.gray900.text} >
                    {heading}
                </Text>

                <div className='sm:px-0 grid md:grid-cols-2 lg:flex flex-wrap gap-6 lg:gap-y-9 lg:gap-5 lg:justify-center relative z-1 lg:flex-nowrap lg:flex-row items-start'>
                    <div className='absolute left-[30px] sm:left-[40px] md:left-auto md:top-[30px] border-l-2 lg:border-b-2 md:border-l-0 border-gray-400 border-dashed h-full md:h-0 md:w-9/12'></div>
                    {list?.map((item, index) => (
                        <>
                            <NgSteps className='gap-4 sm:gap-2 md:gap-y-2' isHowItWork={true} key={item?.id} src={item?.src} description={item?.description} isEven={index % 2 == 0} isLastEl={index == list.length - 1} />
                            {/* {index != list.length - 1 && <IconAngleRight className='hidden lg:block w-10 fill-gray-400 mt-[53px] relative z-2' />} */}
                        </>
                    ))}
                </div>

            </Container>
        </div>
    )
}

export default HowItWorkSteps