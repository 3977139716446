import React, { useEffect, useState } from 'react'
import Modal from 'atoms/Modal'
import HeaderForModalV2 from 'molecules/Modals/HeaderForModalV2'
import BodyForModal from 'molecules/Modals/BodyForModal'
import Text from 'atoms/Text'
import Card from 'atoms/Card'
import List from 'atoms/List'
import Heading from 'atoms/Heading'
import ConsultDoctorList from '../HomePage/ConsultDoctorList'
import ColorBgListing from '../HomePage/ColorBgListing'
import LinkAnchor from 'atoms/LinkAnchor'
import HighlightLogin from '../HomePage/HighlightLogin'
import { getAuth } from 'services/identity.service'
import { getClinicDetails } from 'services/clinic.service'
import locationFormatter from 'core/clinic/locationFormatter'


const ModalDiseasesConcern = ({ className, isModalOpen, handleModalStateChange, modalHeading, description, specialitiesList, treatmentList, onRequestAppointment, specialitiesTitle, treatmentTitle, addressTitle, phoneNumber, location, addressSubTitle, shareUrl, doctorTag, ...rest }) => {
    const [isLoadingClinic, setIsLoadingClinic] = useState(rest?.type === 'SMARTCENTER')
    const [clinicDetail, setClinicDetail] = useState({});
    useEffect(async () => {
        if (rest?.type === 'SMARTCENTER') {
            setIsLoadingClinic(true);
            const slug = rest?.slug, city = rest?.city;
            const clinicResponse = await getClinicDetails(slug, city)
            setClinicDetail(clinicResponse?.entity?.[0])
            setIsLoadingClinic(false);
        }
    }, [])

    const auth = getAuth();
    return (
        <Modal
            type="ghost"
            containerClass={`${isModalOpen || 'hidden'}`}
            className={`${className} max-w-full md:max-w-[690px] w-full mb-0 md:mb-7.5 customScrollbar`}
            handlerModalClose={handleModalStateChange}
            isModalOpen={isModalOpen}
            containerPadding='px-0'
            containerAlignment='items-end md:items-center'
            dataAutomation='medicine'
        >

            <div className='flex flex-col min-h-[300px] h-min max-h-[calc(100vh_-_60px)] md:max-h-[calc(100vh_-_150px)] relative customScrollbar'>

                <HeaderForModalV2
                    className='flex-shrink-0 mt-2.5'
                    isCloseButton={true}
                    handlerModalClose={(event) => handleModalStateChange({ event })}
                    dataAutomation='medicine'
                    padding='px-4'
                >{modalHeading}</HeaderForModalV2>
                {!auth && <HighlightLogin className='mb-4' url='/login-individual' />}

                <BodyForModal type="bodyType1" className="px-4 pt-0 pb-4 relative overflow-y-auto flex-grow object-contain items-center justify-center">
                    <Text type='subtitle' className='text-gray-600 font-Open-Sans md:font-normal mt-0 text-xs '>{description}</Text>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-4 mt-5'>
                        <ColorBgListing className='bg-secondary-50' title={specialitiesTitle} list={specialitiesList} />
                        {treatmentList && clinicDetail && <ColorBgListing className='bg-primary1-50' title={treatmentTitle} list={treatmentList} />}
                        {rest?.type === 'SMARTCENTER' && <ColorBgListing address={true} className='bg-primary1-50'
                            title={'Address'}
                            subTitle={clinicDetail?.clinic_name}
                            location={
                                locationFormatter({
                                    address: clinicDetail?.address,
                                    locality: clinicDetail?.locality,
                                    city: clinicDetail?.city,
                                    state: clinicDetail?.state,
                                    country: clinicDetail?.country,
                                    pincode: clinicDetail?.pincode
                                })}
                            phoneNumber={clinicDetail?.mobile}
                            isLoading={isLoadingClinic}
                        />}
                    </div>

                    <div className=' '>
                        <ConsultDoctorList
                            isGridView={true}
                            onRequestAppointment={onRequestAppointment}
                            doctorTag={doctorTag}
                            limit={10}
                            clinicId={rest?.clinicId}
                            isShowSlider={true}
                        />
                    </div>
                </BodyForModal>
            </div>
        </Modal>
    )
}

export default ModalDiseasesConcern