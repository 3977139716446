import React, { useState } from 'react'
import dynamic from 'next/dynamic';

import LoadingStatic from 'components/Loader/LoadingStatic';
import MakeAppointment from '../BookAnAppointment/MakeAppointment';
import ModalDiseasesConcern from '../ModalConsultDoctor/ModalDiseasesConcern';
const loading = () => <LoadingStatic className=' h-40' />;
const ModalConsultDoctor = dynamic(() => import('components/ModalConsultDoctor/ModalConsultDoctor'), { loading });
const ModalDiseaseBanner = dynamic(() => import('components/ModalConsultDoctor/ModalDiseaseBanner'), { loading });

const ConsultDoctorWrapper = ({ isOpenConsultModal, setIsOpenConsultModal, consultDoctor, doctor, setDoctor, isOpenAppointmentModal, setIsOpenAppointmentModal, onBackFromAppointment, openDiseaseModal, setOpenDiseaseModal, isOpenDiseasesModal, setIsOpenDiseasesModal, onDiseaseConcernRequestAppointment, concernArea }) => {
    const onRequestAppointment = (doctor) => {
        setIsOpenConsultModal(false);
        setIsOpenAppointmentModal(true);
        setDoctor({ ...doctor, isFromTopDoctorAppointment: true })
    }

    const onBackToConsultDoctor = (doctor) => {
        setIsOpenConsultModal(true);
        setIsOpenAppointmentModal(false);
    }

    const onDiseaseRequestAppointment = (doctor) => {
        setOpenDiseaseModal({ ...openDiseaseModal, isOpen: false });
        setIsOpenAppointmentModal(true);
        setDoctor({ ...doctor, isFromTopDoctorAppointment: true, type: 'DISEASE' })
    }

    const onBackFromDisease = () => {
        setIsOpenAppointmentModal(false);
        setOpenDiseaseModal({ ...openDiseaseModal, isOpen: true });
    }

    const onBackModal = () => {
        switch (doctor?.type) {
            case 'CONCERN_AREA':
                onBackFromAppointment();
                break;

            case 'DISEASE':
                onBackFromDisease();
                break;

            default:
                onBackToConsultDoctor();
                break;
        }
    }

    return (
        <div>
            {isOpenConsultModal && <ModalConsultDoctor isModalOpen={isOpenConsultModal} handleModalStateChange={() => setIsOpenConsultModal(prev => !prev)}
                consultDoctor={consultDoctor} modalHeading={'Consult a Doctor'} onRequestAppointment={onRequestAppointment} />}

            {openDiseaseModal?.isOpen && <ModalDiseaseBanner
                isModalOpen={openDiseaseModal?.isOpen}
                handleModalStateChange={() => setOpenDiseaseModal(false)}
                doctorTag={openDiseaseModal?.doctorTag}
                onRequestAppointment={onDiseaseRequestAppointment}
                data={openDiseaseModal?.data}
            />}

            {isOpenAppointmentModal && <MakeAppointment
                isModalOpen={isOpenAppointmentModal}
                setIsModalOpen={setIsOpenAppointmentModal}
                section={'individual'}
                buttonId={'makeAppointment'}
                doctorId={doctor?.id}
                doctor={doctor}
                isCoverContent={false}
                isFrontForm={false}
                onBackFromBookAppointment={onBackModal}
                isShowBackFirstPage={doctor?.isFromTopDoctorAppointment}
            />}

            {isOpenDiseasesModal && <ModalDiseasesConcern
                isModalOpen={isOpenDiseasesModal}
                handleModalStateChange={() => setIsOpenDiseasesModal()}
                onRequestAppointment={onDiseaseConcernRequestAppointment}
                modalHeading={concernArea?.title}
                description={concernArea?.info}
                specialitiesTitle={concernArea?.specialitiesTitle}
                specialitiesList={concernArea?.leftList}
                treatmentTitle='Treatment'
                treatmentList={concernArea?.treatments}
                doctorTag={concernArea?.tag}
                {...concernArea}
            />}
        </div>
    )
}

export default ConsultDoctorWrapper